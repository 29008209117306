import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const renderImage=value=>{
  /* console.log('====================================');
  console.log(`value=>${JSON.stringify(value,null,2)}`);
  console.log('===================================='); */
  const {node}= value
  const {childImageSharp}= node
    return (
        <Img fluid={childImageSharp.fluid}/>
    )
}

const ImageTwo = props => {
  const { file} = props
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 915, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={({images})=>renderImage(images.edges.find(image=>image.node.relativePath===file))}
    />
  )
}

export default ImageTwo
